a:hover,
a:focus {
  text-decoration: none;
}

.sidebar-container {
  padding-left: 0;
  transition: all 0.5s ease;
  position: relative;
}

.sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--primary-color);
  transition: all 0.5s ease;
}

.sidebar-container.toggled .sidebar-wrapper {
  width: 250px;
}

.sidebar-brand {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  text-align: center;
  padding-bottom: 20px;
}
.sidebar-brand img{
 width: 150px;
 height: 100%;
}

.sidebar-nav {
  position: absolute;
  top: 90px;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-nav > li {
  text-indent: 10px;
  line-height: 40px;
}
.sidebar-nav > li a {
  display: block;
  text-decoration: none;
  color: #000;
  font-weight: 600;
  font-size: 18px;
}
.sidebar-nav > li > a:hover,
.sidebar-nav > li.active > a {
  text-decoration: none;
  color: #fff;
  background: #3c6b63;
}
.sidebar-nav > li > a svg {
  font-size: 24px;
  width: 60px;
}

.navbar-wrapper {
  width: 100%;
  position: absolute;
  z-index: 2;
}
.sidebar-container.toggled .navbar-wrapper {
  position: absolute;
  margin-right: -250px;
}
.navbar-wrapper .navbar {
  border-width: 0 0 0 0;
  background-color: #eee;
  font-size: 24px;
  margin-bottom: 0;
  border-radius: 0;
}
.navbar-wrapper .navbar a {
  color: #757575;
}
.navbar-wrapper .navbar a:hover {
  color: #a47d09;
}

.content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
  top: 100px;
}
.sidebar-container.toggled .content-wrapper {
  position: absolute;
  margin-right: -250px;
}

.notification-container {
  position: relative;
}

.notification-container .count {
  font-size: 12px;
  position: absolute;
  top: 0;
  left: -5px;
  color: #a70000;
  font-weight: 600;
}

.notification-container svg {
  font-size: 16px;
}

@media (min-width: 992px) {
  .sidebar-container {
    padding-left: 250px;
  }

  .sidebar-container.toggled {
    padding-left: 60px;
  }

  .sidebar-wrapper {
    width: 250px;
  }

  .sidebar-container.toggled .sidebar-wrapper {
    width: 60px;
  }

  .sidebar-container.toggled .navbar-wrapper {
    position: absolute;
    margin-right: -190px;
  }

  .sidebar-container.toggled .content-wrapper {
    position: absolute;
    margin-right: -190px;
  }

  .navbar-wrapper {
    position: relative;
  }

  .sidebar-container.toggled {
    padding-left: 60px;
  }

  .content-wrapper {
    position: relative;
    top: 0;
  }

  .sidebar-container.toggled .navbar-wrapper,
  .sidebar-container.toggled .content-wrapper {
    position: relative;
    margin-right: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar-container {
    padding-left: 60px;
  }

  .sidebar-wrapper {
    width: 60px;
  }

  .sidebar-container.toggled .navbar-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  .sidebar-container.toggled .content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  .navbar-wrapper {
    position: relative;
  }

  .sidebar-container.toggled {
    padding-left: 250px;
  }

  .content-wrapper {
    position: relative;
    top: 0;
  }

  .sidebar-container.toggled .navbar-wrapper,
  .sidebar-container.toggled .content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}

@media (max-width: 767px) {
  .sidebar-container {
    padding-left: 0;
  }

  .sidebar-wrapper {
    width: 0;
  }

  .sidebar-container.toggled .sidebar-wrapper {
    width: 250px;
  }
  .sidebar-container.toggled .navbar-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  .sidebar-container.toggled .content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  .navbar-wrapper {
    position: relative;
  }

  .sidebar-container.toggled {
    padding-left: 250px;
  }

  .content-wrapper {
    position: relative;
    top: 0;
  }

  .sidebar-container.toggled .navbar-wrapper,
  .sidebar-container.toggled .content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}
