* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #9eddff;
  --error: #ff3838;
  --white: #fff;
  --green: #00b678;
  --red: #b60600;
  --yellow: #70b600;
  --Pink: #fe249a;
  --orange: #e46e00;
  --blue: #5458e1;
}

.primary-button {
  background-color: var(--primary-color) !important; 
  color: #000 !important;
  border: 0 !important;
}

.font-12 {
  font-size: 12px;
}
.font-15 {
  font-size: 15px;
}
.pointer {
  cursor: pointer;
}

.colour-light {
  color: #747474;
}

.font-weight-bold {
  font-weight: bold;
}

.error-message {
  color: var(--error);
}

.text-green {
  color: var(--green);
}

.text-blue {
  color: var(--blue);
}

.text-red {
  color: var(--red);
}

.text-yellow {
  color: var(--yellow);
}
.text-pink {
  color: var(--Pink);
}

.text-orange {
  color: var(--orange);
}
.bg-green {
  color: var(--white);
  background-color: var(--green);
}

.bg-blue {
  color: var(--white);
  background-color: var(--blue);
}
.bg-red {
  color: var(--white);
  background-color: var(--red);
}
.bg-yellow {
  color: var(--white);
  background-color: var(--yellow);
}
.bg-pink {
  color: var(--white);
  background-color: var(--Pink);
}
.bg-orange {
  color: var(--white);
  background-color: var(--orange);
}
.border-green {
  border-left: 5px solid var(--green);
}
.border-blue {
  border-left: 5px solid var(--blue);
}
.border-red {
  border-left: 5px solid var(--red);
}
.border-yellow {
  border-left: 5px solid var(--yellow);
}
.border-pink {
  border-left: 5px solid var(--Pink);
}
.border-orange {
  border-left: 5px solid var(--orange);
}

.bg-green {
  color: var(--white);
  background-color: var(--green);
}

.bg-blue {
  color: var(--white);
  background-color: var(--blue);
}

.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container .card {
  background-color: #fafafa;
}
.login-container .card .card-body {
  width: 600px;
}

.users-tabs button {
  width: 300px;
  color: #222 !important;
}

.users-tabs button.active {
  background: #222 !important;
  color: #fff !important;
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-form img {
  width: 300px;
  height: 200px;
}

.uploadImage-container {
  position: relative;
  cursor: pointer;
  max-width: 200px;
  max-height: 240px;
}
.uploadImage-container img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.uploadImage-container input {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 2rem;
  opacity: 0;
}

.uploadImage-container button {
  background-color: #575757;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-left: 45px;
}

.dash__card {
  box-shadow: 0 0 20px #b9b9b9;
  padding: 30px 50px;
  margin: 10px 0px;
  border-radius: 8px;
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash__card .dash__card_icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash__card .dash__card_icon svg {
  fill: white;
  width: 40px;
  height: 40px;
}

.dash__card p {
  margin: 10px 0;
  font-size: 1.6rem;
}

.pagination-container svg {
  font-size: 20px;
}

.pagination-container svg polygon {
  fill: #fff;
}

.delete-field {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 30px;
}

.delete-field:hover svg {
  fill: #000;
  cursor: pointer;
}
